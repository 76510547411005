import React from 'react';

/**
 * TODO
 * @category Utils
 */
export function buildWrapperDisplayName(wrapperName: string, SourceComponent: React.ComponentType<any>): string {
    const componentName = SourceComponent.displayName ||
        SourceComponent.name ||
        'Component';

    return `${wrapperName}(${componentName})`;
}

/**
 * TODO
 * @category Utils
 */
export function withWrapperDisplayName<T extends React.ComponentType<any>>(WrapperComponent: T, wrapperName: string, SourceComponent: React.ComponentType<any>): T {
    WrapperComponent.displayName = buildWrapperDisplayName(wrapperName, SourceComponent);
    return WrapperComponent;
}