import {
    ApiDataResponse,
    BaseApiResponse,
} from '@frontend/jetlend-core/src/models/api';
import {
    IEmailFormValues,
    IPhoneRegistrationFormValues,
    IRegistrationFormValues,
    IRegistrationResultApiModel,
} from '@app/models/common/registration';
import { apiPostRequest } from '../api';
import { buildClientApiEndpoint } from './common';
import { buildIntegrityHeaders } from '../integrity';

export async function apiPostPhoneRegistration(values: IPhoneRegistrationFormValues): Promise<BaseApiResponse> {
    const {
        clientType,
        ...payload
    } = values;

    // return await testErrorResponse('Пользователь уже зарегистрирован');
    // return await testEmptyResponse();

    return apiPostRequest(buildClientApiEndpoint(clientType, '/api/register/phone'), payload);
}

export async function apiPostUserRegistration(values: IRegistrationFormValues): Promise<ApiDataResponse<IRegistrationResultApiModel>> {
    const {
        clientType,
        ...payload
    } = values;

    // return await testErrorResponse('Ошибка создания аккаунта');
    // return await testEmptyResponse(3000);

    // if(payload.redirect_uri) {
    //     return await testResponse({
    //         redirect_uri: payload.redirect_uri,
    //     });
    // } else {
    //     return await testEmptyResponse();
    // }

    return apiPostRequest(buildClientApiEndpoint(clientType, '/api/register'), payload, { headers: await buildIntegrityHeaders('register') });
}

export async function apiPostProfileEmailUpdate(values: IEmailFormValues): Promise<BaseApiResponse> {
    const {
        clientType,
        ...payload
    } = values;

    return apiPostRequest(buildClientApiEndpoint(clientType, '/api/profile/email/edit'), payload);
}