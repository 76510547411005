import {
    isEmptyValue,
    ValidationResult,
    ValidatorFn,
} from './common';

/**
 * TODO
 * @category Validators
 */
export function requireTrue(): ValidatorFn {
    return (value): ValidationResult => {
        if (isEmptyValue(value)) {
            return undefined;
        }

        if (value !== true) {
            return 'Поставьте галочку';
        }

        return undefined;
    };
}
