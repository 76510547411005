import { apiPostRequest } from '../api';
import { buildIntegrityHeaders } from '../integrity';
import { buildClientApiEndpoint } from './common';

export async function apiSmsSend(values) {
    const {
        clientType,
        ...payload
    } = values;

    // return { status: 'skip' };
    // return await testPostResponse(666, 'id');

    return apiPostRequest(buildClientApiEndpoint(clientType, '/api/sms/send_code'), payload, { headers: await buildIntegrityHeaders('send_sms') });
}

export async function apiSmsConfirm(values) {
    const {
        clientType,
        ...payload
    } = values;

    // return await testEmptyResponse();

    return apiPostRequest(buildClientApiEndpoint(clientType, '/api/sms/verify_code'), payload, { headers: await buildIntegrityHeaders('verify_code') });
}
