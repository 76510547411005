import { numWordForm } from '@core/utils/langUtils';
import {
    isEmptyValue,
    ValidatorFn,
} from './common';

export interface RequireLengthOptions {
    min?: number;
    max?: number;
}

function getLength(value: any): number|undefined {
    if (typeof value === 'string') {
        return value.length;
    }

    if (typeof value === 'object' && Array.isArray(value)) {
        return value.length;
    }

    return undefined;
}

/**
 * TODO
 * @category Validators
 */
export function isRequiredLength(value: any, options: RequireLengthOptions): boolean {
    const length = getLength(value);
    if (typeof length === 'undefined') {
        return false;
    }

    if (typeof options.max !== 'undefined') {
        if (length > options.max) {
            return false;
        }
    }

    if (typeof options.min !== 'undefined') {
        if (length < options.min) {
            return false;
        }
    }

    return true;
}

/**
 * TODO
 * @category Validators
 */
export function requireLength(options: RequireLengthOptions): ValidatorFn {
    return value => {
        if (isEmptyValue(value)) {
            return undefined;
        }

        if (typeof value === 'string') {
            const length = value.length;

            if (typeof options.min !== 'undefined' && length < options.min) {
                return `Длина текста должна быть больше ${numWordForm(options.min, 'chars_parental')}`;
            }

            if (typeof options.max !== 'undefined' && length > options.max) {
                return `Длина текста должна быть меньше ${numWordForm(options.max, 'chars_parental')}`;
            }

            return undefined;
        }

        if (typeof value === 'object' && Array.isArray(value)) {
            const length = value.length;

            if (typeof options.min !== 'undefined' && length < options.min) {
                return `Необходимо выбрать как минимум ${numWordForm(options.min, 'values')}`;
            }

            if (typeof options.max !== 'undefined' && length > options.max) {
                return `Необходимо выбрать не более ${numWordForm(options.max, 'values')}`;
            }

            return undefined;
        }

        return 'Значением должен быть текст или массив значений';
    };
}
