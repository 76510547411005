import * as Sentry from '@sentry/react';

/**
 * TODO
 * @category Utils
 */
export function safe(func: () => any): any {
    try {
        return func();
    } catch (e) {
        console.error('safe', e);
    }
}

export type ClientType = 'investor'|'borrower'|'partner'|'employee';

export interface ClientInfo {
    id?: number;
    clientType?: ClientType;
    clientId?: string;
    name?: string;
    phone?: string;
    email?: string;
}

/**
 * TODO
 * @category Utils
 */
export function setupChat(info: ClientInfo): void {
    const jivoApi = (window as any).jivo_api;

    const jivoCallback = () => {
        jivoApi.setContactInfo({
            name: info.name,
            phone: info.phone,
            email: info.email,
        });
    };

    if (jivoApi && jivoApi.setContactInfo) {
        safe(() => jivoCallback());
    } else {
        (window as any).jivo_onLoadCallback = jivoCallback;
    }
}

/**
 * TODO
 * @category Utils
 */
export function setupRollbar(info: ClientInfo): void {
    safe(() => (window as any).Rollbar.configure({
        payload: {
            person: {
                id: info.clientId,
                username: info.name,
                email: info.email,
            },
        },
    }));
}

/**
 * @deprecated
 */
export function setupMouseFlow(info: ClientInfo): void {
    safe(() => {
        (window as any)._mfq.push(['identify', info.clientId]);
        (window as any)._mfq.push(['setVariable', 'type', info.clientType]);
    });
}

/**
 * TODO
 * @category Utils
 */
export function setupAnalyticsData(info: ClientInfo): void {
    safe(() => {
        (window as any).dataLayer.push({
            'event': 'tracking',
            'type': info.clientType,
            'id': info.id,
            'client_id': info.clientId,
            'phone': info.phone,
            'email': info.email,
            'name': info.name,
        });
    });
}

/**
 * TODO
 * @category Utils
 */
export function setupSentryUser(info: ClientInfo): void {
    Sentry.setUser({
        id: info.clientId,
        username: info.name,
        email: info.email,
        segment: info.clientType,
    });
}