import {
    isEmptyValue,
    ValidationResult,
    ValidatorFn,
} from './common';

/**
 * TODO
 * @category Validators
 */
export function isFloatValue(value: any): boolean {
    if (typeof value === 'number') {
        return !Number.isNaN(value);
    }

    if (typeof value === 'string') {
        return /^-?[0-9\s]+([.,][0-9]*)?$/.test(value?.trim());
    }

    return false;
}

/**
 * TODO
 * @category Validators
 */
export function requireFloat(): ValidatorFn {
    return (value): ValidationResult => {
        if (isEmptyValue(value)) {
            return undefined;
        }

        if (!isFloatValue(value)) {
            return 'Необходимо ввести корректное число';
        }

        return undefined;
    };
}