import {
    isEmptyValue,
    ValidationResult,
    ValidatorFn,
} from './common';

export interface SameValidatorOptions {
    fieldName: string;
    fieldTitle?: string;
}

/**
 * TODO
 * @category Validators
 */
export function requireSame(options: SameValidatorOptions): ValidatorFn {
    return (value: any, form: { [fieldName: string]: any }): ValidationResult => {
        if (isEmptyValue(value)) {
            return undefined;
        }

        const message = `Должно совпадать с полем ${options.fieldTitle || options.fieldName}.`;

        const expectedValue = form?.[options.fieldName];
        if (isEmptyValue(expectedValue)) {
            return message;
        }

        if (value !== expectedValue) {
            return message;
        }

        return undefined;
    };
}