import { ClientType } from '@app/models/common/common';

export const CLIENT_TYPE_API_MAP: Record<ClientType, string> = {
    [ClientType.Investor]: 'invest',
    [ClientType.Borrower]: 'lend',
    [ClientType.Partner]: 'partner',
};

export function buildClientApiEndpoint(clientType: ClientType, endpoint: string): string {
    return  `~/${CLIENT_TYPE_API_MAP[clientType]}${endpoint}`;
}

