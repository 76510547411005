import {
    ExtractType,
    NestedKeyOf,
} from '@core/types';

/**
 * Extract object field value by specified path.
 * Supports nesting / array / field accessing.
 *
 * @example
 * const object = {
 *   name: 'Simplifier',
 *   times: [1, 2, 3],
 *   position: { x: 10.2, y: -12.5 },
 *   path: [
 *     { x: 10.2, y: -12.5 },
 *     { x: 0, y: 0 },
 *   ],
 * };
 *
 * getFieldByPath(object, 'name') => 'Simplifier'
 * getFieldByPath(object, 'position.x') => 10.2
 * getFieldByPath(object, 'path[0].y') => -12.5
 *
 * @param object The object with fields and values.
 * @param path The path in JS like format.
 * @returns The value of the field under path.
 * @category Utils
 */
export function getFieldByPath<TObject, TFieldPath extends NestedKeyOf<TObject>>(object: TObject, path: TFieldPath): ExtractType<TObject, TFieldPath> {
    if (!object || !path) {
        return undefined;
    }

    if (typeof object !== 'object') {
        return undefined;
    }

    const normalizedPath = path
        // convert indexes to properties
        .replace(/\[(\w+)\]/g, '.$1')
        // strip a leading dot
        .replace(/^\./, '');

    const parts = normalizedPath.split('.');
    let lookupObject = object;
    for (let idx = 0, n = parts.length; idx < n; ++idx) {
        const fieldAccessor = parts[idx];
        if (fieldAccessor in lookupObject) {
            lookupObject = lookupObject[fieldAccessor];
        } else {
            return undefined;
        }
    }

    return lookupObject as any;
}
