import {
    isEmptyValue,
    ValidatorFn,
    ValidationResult,
} from './common';
import { isInteger } from './requireInteger';

export enum PaymentAccountType {
    PERSONAL_ACCOUNT = '40817',
    INDIVIDUAL_ACCOUNT = '40802',
    COMPANY_ACCOUNT = '407',
    CORR_ACCOUNT = '301',
}

const getAccountMessage = (accountType?: PaymentAccountType) => accountType
    ? `Номер счета должен начинаться с ${accountType}...`
    : 'Номер счета не соответствует критериям платформы. Пожалуйста, обратитесь в поддержку.';

/**
 * TODO
 * @category Validators
 */
export function isAccountNumberRequired(value: any, accountType?: PaymentAccountType): boolean {
    if (accountType) {
        return new RegExp(`^${accountType}`).test(value);
    }
    const regPayment = new RegExp(`^${PaymentAccountType.PERSONAL_ACCOUNT}|^${PaymentAccountType.INDIVIDUAL_ACCOUNT}|^${PaymentAccountType.COMPANY_ACCOUNT}`);
    return regPayment.test(value);

}

/**
 * TODO
 * @category Validators
 */
export function requireAccountNumber(accountType?: PaymentAccountType): ValidatorFn {
    return (value): ValidationResult => {
        const length = value.length;

        if (isEmptyValue(value)) {
            return undefined;
        }

        if (!isInteger(value)) {
            return 'Номер счета должен состоять только из цифр';
        }

        if (!isAccountNumberRequired(value, accountType)) {
            return getAccountMessage(accountType);
        }

        if (length !== 20) {
            return 'Номер счета должен состоять из 20 цифр';
        }

        return undefined;
    };
}
