import { withRecaptcha } from '@frontend/jetlend-web-ui/src/services/recaptchaService';

declare global {
    interface Window {
        INTEGRITY_TYPE?: string;
    }
}

/**
 * Функция создания заголовков целостности
 * @param recaptchaAction действие
 */
export async function buildIntegrityHeaders(recaptchaAction: string) {
    const token = await withRecaptcha(recaptchaAction);

    return {
        'X-Integrity-Type': (window as any).INTEGRITY_TYPE,
        'X-Integrity-Token': token,
    };
}