/**
 * @returns `undefined` in case if validation are success on given input,
 * either returns the message of the error.
 */
export type ValidationResult = undefined|string;
export type ValidatorFn = (value: any, form: { [fieldName: string]: any }) => ValidationResult;
export type ValidatorFactory = (options?: any) => ValidatorFn;

/**
 * TODO
 * @category Validators
 */
export function isEmptyValue(v: any): boolean {
    if (typeof v === 'undefined') {
        return true;
    }

    if (v === undefined || v === null) {
        return true;
    }

    if (typeof v === 'string') {
        return v?.length === 0;
    }

    if (Array.isArray(v)) {
        return v?.length === 0;
    }

    if (typeof FileList !== 'undefined' && v instanceof FileList) {
        return v?.length === 0;
    }

    return false;
}

/**
 * TODO
 * @category Validators
 */
export function parseFloatExt(value): number {
    if (typeof value === 'string') {
        value = value
            .replace(',', '.')
            .replace(/\s+/g, '');

        if (value.charAt(value.length - 1) === '.') {
            value = value.substring(0, value.length - 1);
        }
    }

    return parseFloat(value);
}

/**
 * TODO
 * @category Validators
 */
export function validate(value, values, validators: ValidatorFn[]): ValidationResult {
    if (!validators || validators.length === 0) {
        return undefined;
    }

    for (let idx = 0; idx < validators.length; idx++) {
        const error = validators[idx](value, values);
        if (error) {
            return error;
        }
    }

    return undefined;
}