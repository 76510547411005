import { ISmsSigned } from '@frontend/jetlend-core/src/models/sms';
import {
    ClientType,
    CommonStage,
} from './common';
import { ExternalAuthVariant } from '@app/models/common/oauth';

export interface IPhoneRegistrationFormValues {
    clientType?: ClientType;
    phone?: string;
}

export interface IEmailFormValues {
    clientType?: ClientType;
    email?: string;
}

export enum RegistrationDeviceType {
    LegacyWeb = 'legacy-web',
    DesktopWeb = 'desktop-web',
    MobileWeb = 'mobile-web',
    Application = 'app',
}

export interface IRegistrationFormValues extends ISmsSigned, IPhoneRegistrationFormValues {
    promocode?: string;
    segment?: string;
    device_type?: RegistrationDeviceType;
    /**
     * uri для редиректа
     */
    redirect_uri?: string;
}

export interface IRegistrationState {
    clientType?: ClientType;
    stage?: CommonStage;
    phone?: string;
    email?: string;
    userExists?: string;
    isChangePhonePressed?: boolean;
    redirectUri?: string;
    loadingTitle?: string;
    /**
     * Сообщение ошибки
     */
    errorMessage?: string;
}

export interface IRegistrationResultApiModel {
    redirect_uri?: string;
}

/**
 * Интерфейс, содержащий информацию о типе пользователя.
 * @param clientType -Тип авторизуемого пользователя при регистрации.
 */
export type GetExternalRegistrationValues = Pick<IPhoneRegistrationFormValues, 'clientType'>

/**
 * Интерфейс содержащий информацию о пользователе для регистрации с использованием стороннего ресурса.
 * @param auth_variant - Вариант используемого ресурса.
 * @param device_type - Тип устройства пользователя.
 * @param return_uri - URI для последующего редиректа пользователя.
 */
export interface IExternalRegistrationValues extends GetExternalRegistrationValues {
    auth_variant: ExternalAuthVariant;
    device_type: RegistrationDeviceType;
    return_uri: string;
}
