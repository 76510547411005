import {
    isEmptyValue,
    ValidationResult,
    ValidatorFn,
} from './common';

/**
 * TODO
 * @category Validators
 */
export function required(): ValidatorFn {
    return (value): ValidationResult => {
        if (isEmptyValue(value)) {
            return 'Заполните поле';
        }

        return undefined;
    };
}
