import {
    all,
    call,
    put,
    take,
    takeEvery,
} from '@redux-saga/core/effects';
import { combineReducers } from 'redux';
import {
    change,
    reset,
    actionTypes as FormActionTypes,
} from 'redux-form';
import { createType } from '@frontend/jetlend-core/src/ducks/core';
import { objectHandler } from '@frontend/jetlend-core/src/ducks/object';
import { createUpdateAction } from '@frontend/jetlend-core/src/store/actions';
import { addToastError } from '@frontend/jetlend-core/src/ducks/toasts';
import { createSelector } from 'reselect';
import { ISmsCodeApproveState } from '@frontend/jetlend-core/src/models/sms';
import { isValidPhone } from '@frontend/jetlend-core/src/validators';
import { safeSaga } from '@frontend/jetlend-core/src/ducks/utils';
import { sendEvent } from './analytics';
import {
    apiSmsConfirm,
    apiSmsSend,
} from '@app/services/client/common/smsService';
import {
    CLIENT_TYPE_SCOPE_MAP,
    ClientType,
} from '@app/models/common/common';

export const PREFIX = 'sms';

const smsHandler = objectHandler<ISmsCodeApproveState>(PREFIX, 'sms');
export const getSmsState = smsHandler.selector;

const CLOSE = createType(PREFIX, 'CLOSE');
const SUBMIT = createType(PREFIX, 'SUBMIT');
const RETRY = createType(PREFIX, 'RETRY');

export const closeSmsDialog = createUpdateAction(CLOSE);
export const confirmSmsDialog = createUpdateAction(SUBMIT);
export const retrySmsDialog = createUpdateAction(RETRY);

function validate({ code }) {
    const errors: any = {};

    if (code) {
        const trimmedCode = code.trim();
        const isDigitalCode = /^[0-9]+$/.test(trimmedCode);
        const isCodeValid = code && (isDigitalCode && (trimmedCode.length === 4 || trimmedCode.length === 8)) || trimmedCode.startsWith('j');

        if (!isCodeValid) {
            errors.code = true;
        }
    } else {
        errors.code = true;
    }

    return errors;
}

export const form = {
    form: PREFIX,
    validate,
    formValues: createSelector(
        (state: any) => state.form[PREFIX] || {},
        ({ values }) => values
    ),
};

export const reducer = combineReducers({
    ...smsHandler.reducerInfo,
});

function* resetSmsDialogSaga() {
    // reset SMS dialog - call update without parameters
    yield put(smsHandler.update(undefined));

    // reset form data
    yield put(reset(PREFIX));
}

function* enterCodeSaga(clientType: ClientType, smsId) {
    while (true) {
        const action = yield take([ SUBMIT, CLOSE, RETRY ]);

        switch (action.type) {
        case RETRY: {
            return 'retry';
        }
        case SUBMIT: {
            const { code } = action.value;
            const smsConfirmValues = {
                clientType,
                sms_id: smsId,
                code,
            };

            yield put(smsHandler.update({
                error: '',
                loading: true,
            }));

            try {
                const resp = yield call(apiSmsConfirm, smsConfirmValues);
                console.log('SMS CONFIRM', smsConfirmValues, '->', resp);

                switch (resp.status) {
                case 'valid':
                case 'OK':
                    if (clientType) {
                        yield put(sendEvent(`guest-${clientType}--sms-success`));
                    }
                    yield put(sendEvent('guest--sms-success'));

                    return 'success';
                case 'invalid':
                    yield put(smsHandler.update({
                        error: 'Неверный код подтверждения',
                    }));
                    yield put(change(form.form, 'code', ''));
                    continue;
                case 'error':
                    yield put(smsHandler.update({
                        error: resp.error,
                    }));
                    continue;
                default:
                    throw new Error(resp);
                }
            } catch (e) {
                console.error('SMS VALIDATION FAILED', e);
                yield put(smsHandler.update({
                    loading: false,
                    error: 'Ошибка, попробуйте ввести код снова',
                }));
                continue;
            } finally {
                yield put(smsHandler.update({
                    loading: false,
                }));
            }
        }
        case CLOSE:
            return 'close';
        }
    }
}

export const SMS_ID__SKIP = 'skip';

// TODO Add typings
export function* takeSmsIdSaga(smsSendValues) {
    const DEFAULT_SMS_SEND_ERROR = 'Ошибка отправки SMS, пожалуйста подождите 1 минуту и попробуйте еще раз. Если ошибка будет повторяться, обратитесь к вашему менеджеру.';

    let response;
    try {
        response = yield call(apiSmsSend, smsSendValues);

        console.log('SMS SEND', smsSendValues, '->', response);

        if (response && response.status === SMS_ID__SKIP) {
            return SMS_ID__SKIP;
        }
    } catch (e) {
        yield put(addToastError(DEFAULT_SMS_SEND_ERROR));

        throw e;
    }

    const {
        id: smsId,
        error,
    } = response;

    if (error) {
        yield put(addToastError(error));
        throw new Error(error);
    }

    if (!smsId) {
        yield put(addToastError(DEFAULT_SMS_SEND_ERROR));
        throw new Error(DEFAULT_SMS_SEND_ERROR);
    }

    return smsId;
}

export function* signDataWithSmsSaga(
    clientType: ClientType,
    phone: string,
    values,
    actionName = 'default',
    onSmsInputOpen?: () => void
) {
    try {
        yield call(resetSmsDialogSaga);

        // send SMS code to the client
        // TODO Add model typings
        const smsSendValues = {
            clientType,
            scope: CLIENT_TYPE_SCOPE_MAP[clientType],
            action: actionName,
            data: values,
            phone: undefined,
        };

        // Отправляем номер телефона только если он валидный
        // Это необходимо если нам нужно показать в форме маскированный телефон
        if (phone && isValidPhone(phone)) {
            smsSendValues.phone = phone;
        }

        yield put(smsHandler.update({
            phone,
        }));

        let isSuccess = false;
        let smsId;
        while (true) {
            smsId = yield takeSmsIdSaga(smsSendValues);

            if (smsId === SMS_ID__SKIP) {
                return smsId;
            }

            // open confirmation dialog
            if (onSmsInputOpen) {
                yield call(onSmsInputOpen);
            }

            yield put(smsHandler.update({
                phone,
                smsId,
                isOpen: true,
            }));

            // check entered code
            const status = yield call(enterCodeSaga, clientType, smsId);
            isSuccess = status === 'success';

            if (status === 'retry') {
                yield put(reset(PREFIX));
                continue;
            }

            break;
        }

        // close the dialog
        yield call(resetSmsDialogSaga);

        // return confirmed SMS ID in case of success
        if (isSuccess) {
            return smsId;
        }
    } catch (e) {
        // unexpected error - close the dialog and exit with failure

        // FIXME
        console.error('SMS HANDLER ERROR', e);

        yield call(resetSmsDialogSaga);
    }
}

// export function useSmsDialog(actionName = 'default', confirmationContent?: JSX.Element | React.ReactNode, customTitle?: JSX.Element | React.ReactNode) {
//     return function *(apiValues: any) {
//         const smsId = yield call(openSmsDialogSaga, apiValues, actionName, confirmationContent, customTitle);

//         if (!smsId) {
//             return undefined;
//         }

//         return { ...apiValues, sms_id: smsId };
//     };
// }

export function* rootSaga() {
    yield all([
        takeEvery(action => action.id === FormActionTypes.CHANGE && action.meta.form === form.form, safeSaga(function*() {
            yield put(smsHandler.update({
                error: undefined,
            }));
        })),
    ]);
}
