import axios, { AxiosRequestConfig } from 'axios';

const BASE_API_ENDPOINT = process.env.NEXT_PUBLIC_JETLEND_ENDPOINT;

export async function apiRequest(method, path, data = {}, extra: Partial<AxiosRequestConfig> = {}) {
    const baseEndpoint = BASE_API_ENDPOINT === '/'
        ? document.location.origin
        : BASE_API_ENDPOINT;

    const url = path.startsWith('~')
        ? baseEndpoint + path.substr(1)
        : baseEndpoint + path;

    const resp = await axios({
        method,
        url,
        data,
        withCredentials: true,
        ...extra,
    });

    return resp.data;
}

export async function apiGetRequest(path) {
    return apiRequest('get', path);
}

export async function apiPostRequest(path, data, extra: Partial<AxiosRequestConfig> = {}) {
    return apiRequest('post', path, data, {
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        ...extra,
    });
}

export async function apiPostMultipart(path, data) {
    const formData = new FormData();
    for (const key in data) {
        const value = data[key];
        if (value instanceof FileList) {
            for (let idx = 0; idx < value.length; idx++) {
                formData.append(key, value.item(idx));
            }
        } else {
            formData.append(key, value);
        }
    }

    return apiPostRequest(path, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export async function apiUploadFiles(path, fileList) {
    const formData = new FormData();
    fileList.forEach(({
        key,
        file,
    }) => formData.append(key, file));

    return apiPostRequest(path, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}