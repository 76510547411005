import {
    isEmptyValue,
    ValidationResult,
    ValidatorFn,
} from './common';

export function isValidPhone(v: string): boolean {
    // Remove spaces and other special symbols
    const clearPhone = v.replace(/[\s()-]+/g, '');

    // Validates only RU numbers
    return /^(\+7|7|8)?9[0-9]{9}$/.test(clearPhone);
}

/**
 * TODO
 * @category Validators
 */
export function requirePhone(): ValidatorFn {
    return (value: any): ValidationResult => {
        if (isEmptyValue(value)) {
            return undefined;
        }

        if (!isValidPhone(value)) {
            return 'Укажите корректный номер телефона';
        }

        return undefined;
    };
}