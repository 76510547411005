import {
    parseDate,
    toAPIDate,
} from '@core/formatters/formatUtils';

// ! TODO: Implement requireRange, to able use it with form handlers
type ValidatorReturnType = string | undefined;

interface IDateRangeValidatorBorderParams {
    minDate?: any;
    maxDate?: any;
    validateOnlyEndDate?: boolean;
}

const formatErrorDate = (date: any) => parseDate(date, true)?.format('DD.MM.YYYY');

const isSelectedDateInvalid = (selectedDate, minDate, maxDate) => {
    // Using toAPIDate method to ignore hours in date comparison
    const parsedSelectedDate = parseDate(toAPIDate(selectedDate, true), true);
    const parsedMinDate = parseDate(toAPIDate(minDate, true), true);
    const parsedMaxDate = parseDate(toAPIDate(maxDate, true), true);

    if (parsedMinDate && parsedMaxDate) {
        return !parsedSelectedDate?.isBetween(parsedMinDate, parsedMaxDate, undefined, '[]');
    }
    if (!parsedMinDate && parsedMaxDate) {
        return parsedSelectedDate?.isAfter(parsedMaxDate, undefined);
    }
    if (parsedMinDate && !parsedMaxDate) {
        return parsedSelectedDate?.isBefore(parsedMinDate, undefined);
    }
    return false;
};

const getDateRangeErrorText = (firstSentence, minDate, maxDate): ValidatorReturnType => {
    if (minDate && maxDate) {
        return `${firstSentence} Выбор дат возможен с ${formatErrorDate(minDate)} по ${formatErrorDate(maxDate)}.`;
    }
    if (!minDate && maxDate) {
        return `${firstSentence} Выбор даты возможен по ${formatErrorDate(maxDate)}.`;
    }
    if (minDate && !maxDate) {
        return `${firstSentence} Выбор даты возможен с ${formatErrorDate(minDate)}.`;
    }
    return undefined;
};

/**
 * TODO
 * @category Validators
 */
export function validateDateRange(
    startDate,
    endDate,
    {
        minDate = null,
        maxDate = null,
        validateOnlyEndDate = false,
    }: IDateRangeValidatorBorderParams = {}
): ValidatorReturnType {
    const isStartDateInvalid = isSelectedDateInvalid(startDate, minDate, maxDate);
    const isEndDateInvalid = isSelectedDateInvalid(endDate, minDate, maxDate);

    if (validateOnlyEndDate && (Boolean(endDate) && isEndDateInvalid)) {
        return getDateRangeErrorText('Некорректно указано число.', minDate, maxDate);
    }
    if ((Boolean(startDate) && Boolean(endDate)) && parseDate(startDate, true)?.isAfter(parseDate(endDate, true))) {
        return 'Некорректно указан порядок дат.';
    }
    if ((Boolean(startDate) && Boolean(endDate)) && (isStartDateInvalid || isEndDateInvalid)) {
        return getDateRangeErrorText('Некорректно указан период.', minDate, maxDate);
    }
    return undefined;
}