import {
    call,
    put,
    takeEvery,
} from '@redux-saga/core/effects';
import {
    change,
    FormAction,
    initialize,
    reset,
    updateSyncErrors,
} from 'redux-form';
import { createSelector } from 'reselect';
import { DebounceRejectError } from '@core/async/debounce';
import {
    ValidatorFn,
    validate,
} from '@core/validators/common';
import {
    ExtractType,
    NestedKeyOf,
} from '@core/types';
import { getFieldByPath } from '@core/utils/getFieldByPath';
import {
    IObjectHandler,
    objectHandler,
} from './object';
import { createType } from './core';
import {
    IModalHandler,
    modalHandler,
} from './modal';
import {
    IBaseHandler,
    IFetchUpdateHandler,
    SimpleSelector,
} from './props';
import {
    ApiDataResponse,
    BaseApiResponse,
} from '../models/api';
import {
    NetworkRequestStat,
    UnauthorizedRequestError,
    handleCommonApiErrorsSafe,
    logNetworkError,
} from './api/common';
import { redirectToLogin } from './router';

export class FormSubmitError extends Error {
    public $type: Readonly<string> = 'FormSubmitError';

    private _response: any;

    constructor(message: string, response: any) {
        super(message);

        this._response = response;
    }


    public get response() : any {
        return this._response;
    }
}

function parseFloatExt(value: string): number {
    if (typeof value === 'string') {
        value = value.replace(',', '.');
    }
    return parseFloat(value);
}

function applyValidator(values, errors, keys, validator, message) {
    const flatKeys = (keys || []).reduce((acc, v) => acc.concat(v), []);

    for (const key of flatKeys) {
        if (key in errors) {
            return;
        }

        let value = values?.[key];
        if (typeof value === 'string') {
            value = value.trim();
        }

        validator(value);
        if (!validator(value)) {
            errors[key] = message;
        }
    }
}

function createFormValidationV2<TModel>(config: FormValidationConfigV2<TModel>) {
    return values => {
        const {
            debug,
            extraValidate,
            ...formValidators
        } = config;

        const errors = {};
        Object.keys(formValidators).forEach((fieldName: string) => {
            const fieldError = validate(values[fieldName], values, config[fieldName]);
            if (fieldError) {
                errors[fieldName] = fieldError;
            }
        });

        if (extraValidate !== undefined) {
            extraValidate(values, errors);
        }

        if (debug && errors && Object.keys(errors).length > 0) {
            console.log(errors);
        }

        return errors;
    };
}

function createFormValidate<TModel>(validationOptions: FormValidate<TModel>) {
    if (typeof validationOptions === 'object') {
        if (validationOptions.v2) {
            return createFormValidationV2(validationOptions.v2);
        }
    }

    return values => {
        if (typeof validationOptions === 'function') {
            validationOptions = validationOptions();
        }

        const {
            required = [],
            requiredTrue = [],
            integer = [],
            nnegInteger = [],
            positiveInteger = [],
            float = [],
            nnegFloat = [],
            positiveFloat = [],
            date = [],
            extraValidate,
            debug = false,
        } = validationOptions;

        const errors = {};

        applyValidator(
            values, errors, [
                required,
                integer, nnegInteger, positiveInteger,
                date,
            ], v => (v !== undefined && v !== ''), 'Заполните поле'
        );

        applyValidator(
            values, errors, [ requiredTrue ], v => (v === true), 'Поставьте галочку'
        );

        applyValidator(
            values, errors, [integer, nnegInteger, positiveInteger], v => /^[0-9]+$/.test(v), 'Некорректное значение'
        );
        applyValidator(
            values, errors, nnegInteger, v => parseInt(v) >= 0, 'Введите неотрицательное значение'
        );
        applyValidator(
            values, errors, positiveInteger, v => parseInt(v) > 0, 'Введите положительное значение'
        );

        applyValidator(
            values, errors, [float, nnegFloat, positiveFloat], v => (v === undefined || v === '') || /^[0-9.,]+$/.test(v), 'Некорректное значение'
        );
        applyValidator(
            values, errors, nnegFloat, v => (v === undefined || v === '') || parseFloatExt(v) >= 0, 'Введите неотрицательное значение'
        );
        applyValidator(
            values, errors, positiveFloat, v => parseFloatExt(v) > 0, 'Введите положительное значение'
        );

        applyValidator(
            values, errors, date, v => /^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/i.test(v), 'Введите дату в формате ДД.ММ.ГГГГ'
        );

        if (extraValidate !== undefined) {
            extraValidate(values, errors);
        }

        if (debug && errors && Object.keys(errors).length > 0) {
            console.log(errors);
        }

        return errors;
    };
}

function createFormSubmitSaga<T = any, R = BaseApiResponse, Base extends IObjectHandler<FormState> = IObjectHandler<FormState>>({
    handler,
    id,
    actionName,
    formName,
    apiMethod,
    apiMethodExt,
    apiConvertValues = values => values,
    apiStatusValidor = resp => (resp as any as BaseApiResponse)?.status === 'OK',
    apiErrorSelector = resp => (resp as any as BaseApiResponse)?.error,
    apiDataSelector = resp => (resp as ApiDataResponse<T>)?.data,
    onBeforeSubmit,
    success,
    failed,
    onSuccess,
    onSuccessActions = [],
    onSuccessUpdate = [],
    onFormError,
    echo = false,
}: InternalFormSubmitSagaOptions<T, R, Base>) {
    return function* submitSaga({ values }) {
        let apiValues = values;

        const networkStat = new NetworkRequestStat();

        try {
            apiValues = yield call(apiConvertValues, values);

            yield put(handler.update({
                error: null,
                submitting: true,
                submittingValues: apiValues,
            }));

            if (onBeforeSubmit !== undefined) {
                apiValues = yield call(onBeforeSubmit, apiValues);

                if (echo) {
                    console.log(`FORM ${id} before submit =>`, apiValues);
                }

                // Prevent following logic due any error at this step
                if (apiValues === undefined) {
                    yield put(handler.update({
                        submitting: false,
                        submittingValues: undefined,
                    }));
                    return;
                }
            }

            if (echo) {
                console.log(`FORM SUBMIT ${id}`, apiValues);
            }

            // call API method
            if (apiMethodExt !== undefined) {
                apiMethod = apiMethodExt(values);
            }

            networkStat.reset();
            const resp = yield call(apiMethod, apiValues);
            networkStat.finish();

            if (echo) {
                console.log(`FORM SUBMIT ${id} done`, resp);
            }

            // handle API response
            if (apiStatusValidor(resp)) {
                // success actions
                if (onSuccess !== undefined) {
                    yield call(onSuccess, resp, apiValues);
                }

                for (const successAction of onSuccessActions) {
                    yield put(successAction);
                }

                for (const affectHandlers of onSuccessUpdate) {
                    yield put(affectHandlers.fetchUpdate());
                }

                yield put(success({
                    values: {
                        response: resp,
                        values: apiValues,
                        data: apiDataSelector(resp),
                    },
                }));
            } else {
                // handle API error
                const error_detail = apiErrorSelector(resp);
                console.warn(`FORM SUBMIT ${id} error: ${error_detail}`, resp);

                yield put(handler.update({ error: error_detail }));
                if (onFormError) {
                    yield call(onFormError, error_detail, resp, apiValues);
                }

                yield put(failed({
                    values: {
                        error: error_detail,
                        response: resp,
                        values: apiValues,
                    },
                }));
            }
        } catch (e) {
            if (e instanceof DebounceRejectError) {
                return;
            }

            if (e instanceof UnauthorizedRequestError) {
                const error_detail = 'Access denied';
                yield put(handler.update({ error: error_detail }));
                if (onFormError) {
                    yield call(onFormError, error_detail, e.response, apiValues);
                }
                yield put(failed({
                    values: {
                        error: error_detail,
                        response: e.response,
                        values: apiValues,
                    },
                }));

                console.warn(`FETCH ${id} access denied`);
                yield put(redirectToLogin());
                return;
            }

            if (e instanceof FormSubmitError && e.$type === 'FormSubmitError') {
                logNetworkError(`FORM SUBMIT ${id} callback error`, undefined, networkStat, e);

                const error_detail = apiErrorSelector(e.response);

                yield put(handler.update({ error: error_detail }));
                if (onFormError) {
                    yield call(onFormError, error_detail, e.response, apiValues);
                }
                yield put(failed({
                    values: {
                        error: error_detail,
                        response: e.response,
                        values: apiValues,
                    },
                }));
                return;
            }

            const response = e.response;

            if (response && response?.status === 400) {
                console.warn(`FORM SUBMIT ${id} data error`, apiValues, response.data);

                if (response.data) {
                    const build_field_error = (field_name: string, input_error: any, unknown_error = 'Ошибка'): string => {
                        if (typeof input_error === 'string') {
                            return input_error;
                        } if (Array.isArray(input_error)) {
                            return input_error
                                .filter(it => typeof it === 'string')
                                .map((txt: string) => txt.replace(/\s*\.\s*$/, ''))
                                .join('; ');
                        } if (typeof input_error !== 'undefined' && input_error !== null) {
                            console.error(`FORM ${id} Unknown error data for field "${field_name}"`, input_error);
                        }

                        return unknown_error;
                    };

                    const {
                        non_field_errors,
                        ...field_errors
                    } = response.data;

                    const converted_field_errors = Object.keys((field_errors || {}))
                        .reduce((carry: any, field_name: string): any => {
                            const field_error = field_errors[field_name];
                            if (field_error) {
                                carry[field_name] = build_field_error(field_name, field_errors[field_name]);
                            }

                            return carry;
                        }, {});

                    const error_detail = build_field_error('non_field_errors', non_field_errors, 'Неизвестная ошибка данных');

                    yield put(updateSyncErrors(
                        formName,
                        converted_field_errors,
                        error_detail
                    ));

                    if (onFormError) {
                        yield call(onFormError, error_detail, response, apiValues);
                    }
                    yield put(failed({
                        values: {
                            error: error_detail,
                            response,
                            values: apiValues,
                        },
                    }));

                    return;
                }
                const error_detail = 'Непредвиденная ошибка, пожалуйста попробуйте позже или обратитесь к вашему менеджеру';
                yield put(handler.update({ error: error_detail }));
                yield put(failed({
                    values: {
                        error: error_detail,
                        response,
                        values: apiValues,
                    },
                }));
                return;

            }

            if (response && response.data) {
                const resp = response.data;

                const error_detail = apiErrorSelector(resp);
                if (error_detail) {
                    logNetworkError(`FORM SUBMIT ${id} error`, { error_detail }, networkStat, e);

                    yield put(handler.update({ error: error_detail }));

                    if (onFormError) {
                        yield call(onFormError, error_detail, resp, apiValues);
                    }
                    yield put(failed({
                        values: {
                            error: error_detail,
                            response,
                            values: apiValues,
                        },
                    }));
                    return;
                }
            }

            // unexpected error

            yield handleCommonApiErrorsSafe(`FETCH ${id} exception`, apiValues, networkStat, e);

            const error_detail = 'Непредвиденная ошибка, пожалуйста попробуйте позже или обратитесь к вашему менеджеру';
            yield put(handler.update({ error: error_detail }));
            yield put(failed({
                values: {
                    error: error_detail,
                    response,
                    values: apiValues,
                },
            }));
        } finally {
            yield put(handler.update({
                submitting: false,
                submittingValues: undefined,
            }));
        }
    };
}

interface FormOptions<TModel, TBaseHandler extends IBaseHandler> {
    handler: IBaseHandler & TBaseHandler;
    initialValues: Partial<TModel>;
}

export type FormValidateResult<TModel> = {
    [P in keyof TModel]?: string;
} & {
    _error?: string;
}

export type FormValidationConfigV2<TModel> =
{
    debug?: boolean;
    extraValidate?: (values: Partial<TModel>, errors: FormValidateResult<TModel>) => void;
} & {
    [P in keyof TModel]?: ValidatorFn[];
};

export interface IFormValideOptions<TModel> {
    required?: Array<keyof TModel>;
    requiredTrue?: Array<keyof TModel>;
    integer?: Array<keyof TModel>;
    nnegInteger?: Array<keyof TModel>;
    positiveInteger?: Array<keyof TModel>;
    float?: Array<keyof TModel>;
    nnegFloat?: Array<keyof TModel>;
    positiveFloat?: Array<keyof TModel>;
    date?: Array<keyof TModel>;

    extraValidate?: (values: Partial<TModel>, errors: FormValidateResult<TModel>) => void;

    v2?: FormValidationConfigV2<TModel>;
    debug?: boolean;
}

export type FormValidate<TModel> = IFormValideOptions<TModel> | (() => IFormValideOptions<TModel>);

type FormState = any & {
    error?: string;
    submitting?: boolean;
    submittingValues?: any;
    data?: any;
};

/**
 * Реализует хендлер {@link IFormHandler} для работы с формами.
 *
 * @param prefix
 * @param key
 * @param validate
 * @param onSubmitSaga
 * @param param4
 *
 * @category Duck Handlers
 *
 * @see {@link IFormHandler}
 * @see {@link connectToForm}
 * @see {@link connectToField}
 * @see {@link useFormHandler}
 */
export function simpleFormHandler<TModel = any, TResponse = any, TBaseHandler extends IObjectHandler<FormState> = IObjectHandler<FormState>>(
    prefix: string,
    key: string,
    validate: FormValidate<TModel>,
    onSubmitSaga: FormSubmitSagaOptions<TModel, TResponse> | undefined,
    {
        handler = undefined,
        initialValues = {},
    }: Partial<FormOptions<TModel, TBaseHandler>>
): IFormHandler<TModel, TBaseHandler> {
    const id = `${prefix}/${key}`;
    const actionName = key.toUpperCase();

    if (!handler) {
        handler = objectHandler<FormState>(prefix, key) as any;
    }

    let formValidator;
    if (typeof validate === 'object' || typeof validate === 'function') {
        formValidator = createFormValidate(validate);
    }

    const formName = `${prefix}_${actionName}`;

    const FORM_RESET = createType(prefix, `RESET_FORM_${actionName}`);
    const formReset = () => ({ type: FORM_RESET });

    const SUBMIT = createType(prefix, `SUBMIT_${actionName}`);
    const submit = values => ({
        type: SUBMIT,
        values,
    });

    const SUCCESS = createType(prefix, `SUCCESS_${actionName}`);
    const success = values => ({
        type: SUCCESS,
        values,
    });

    const FAILED = createType(prefix, `FAILED_${actionName}`);
    const failed = values => ({
        type: FAILED,
        values,
    });

    const dispatchExt = convert => (values, dispatch, props) => {
        dispatch(submit(convert(values, props)));
    };
    const submitExt = (action, key = 'action') => dispatchExt(values => ({
        [key]: action,
        values,
    }));

    const FORM_CHANGE = createType(prefix, `FORM_CHANGE_${actionName}`);
    const formChange = values => ({
        type: FORM_CHANGE,
        values,
    });

    const submitSagaParams: InternalFormSubmitSagaOptions<TModel, TResponse, TBaseHandler> = {
        ...(onSubmitSaga || {}),
        handler,
        success,
        failed,
        id,
        actionName,
        formName,
    };

    const dataSelector = createSelector(handler.selector, state => state.data);

    const submitSaga = createFormSubmitSaga(submitSagaParams);

    const form = {
        form: formName,
        validate: formValidator,
        initialValues,
    };

    function* formChangeSaga({ values }) {
        for (const field in values) {
            yield put(change(formName, field, values[field]));
        }
    }

    function* formResetSaga() {
        // Reset internal state to initial values
        yield put(handler.update({
            submitting: false,
            error: undefined,
            submittingValues: undefined,
            data: undefined,
        }));

        // Reset REDUX form state
        yield put(reset(formName));
    }

    function* formSuccessSaga({ values }) {
        yield put(handler.update({
            submitting: false,
            error: undefined,
            data: values.values.data,
        }));
    }

    function* formFailedSaga({ values }) {
        yield put(handler.update({
            submitting: false,
            error: values.values.error,
        }));
    }

    const effects = [
        ...(handler?.effects || []),
        takeEvery(SUBMIT as any, submitSaga),
        takeEvery(FORM_CHANGE as any, formChangeSaga),
        takeEvery(FORM_RESET as any, formResetSaga),
        takeEvery(SUCCESS as any, formSuccessSaga),
        takeEvery(FAILED as any, formFailedSaga),
    ];

    const formValuesSelector = createSelector(
        (state: any) => state.form[formName] || {},
        ({ values }) => values as TModel
    );

    const formFieldValueSelector = createSelector(
        [
            formValuesSelector,
            (formValues, fieldName: NestedKeyOf<TModel>) => fieldName,
        ],
        // Use any typecasting to prevent issues with type resolving of internal selector
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (formValues, fieldName) => getFieldByPath(formValues, fieldName as any)
    );

    return {
        ...(handler as any),
        internalSelector: handler?.selector,
        selector: dataSelector,
        SUBMIT,
        submit,
        SUCCESS,
        success,
        FAILED,
        failed,
        dispatchExt,
        submitExt,
        form,
        formName,
        formValues: formValuesSelector,
        formFieldValue: (fieldName => state => formFieldValueSelector(state, fieldName)),
        formError: createSelector(handler.selector, (data: any) => data?.error),
        formReset,
        formSubmitting: createSelector(handler.selector, (data: any) => data?.submitting),
        formSubmittingValues: createSelector(handler.selector, (data: any) => data?.submittingValues),
        formChangeField: (field, value) => change(formName, field, value),
        formInitialize: data => initialize(
            formName, data, false, {
                keepSubmitSucceeded: false,
                keepValues: false,
                updateUnregisteredFields: true,
            }
        ),
        formChange,
        effects,
    };
}

export type FormSubmitter<TModel> = (formValues: TModel, dispatch: (action) => void, props) => void;

/**
 * Реализует хендлеры {@link IModalHandler} и {@link IFormHandler} для реализации модального окна с формой.
 * При успешной отправке данных формы, модальное окно закрывается.
 *
 * @param prefix
 * @param key
 * @param validate
 * @param onSubmitSaga
 * @returns
 *
 * @category Duck Handlers
 *
 * @see {@link IFormHandler}
 * @see {@link IModalHandler}
 * @see {@link formHandler}
 * @see {@link modalHandler}
 * @see {@link connectToForm}
 * @see {@link connectToField}
 * @see {@link useFormHandler}
 * @see {@link useModalHandler}
 * @see {@link useModalHandlerWithKey}
 * @see {@link useIsModalOpen}
 * @see {@link useIsModalOpenWithKey}
 */
export function modalFormHandler<TModel extends object = any, TResponse = any>(
    prefix: string,
    key: string,
    validate: FormValidate<TModel>,
    onSubmitSaga?: FormSubmitSagaOptions<TModel, TResponse>
): IFormHandler<TModel, IModalHandler> {
    const actionName = key.toUpperCase();
    const baseModalHandler = modalHandler(prefix, actionName, key);

    function* onSuccessSaga(resp, values) {
        yield put(baseModalHandler.close());

        // Callback the parent onSubmitSaga when exists
        if (onSubmitSaga && onSubmitSaga.onSuccess) {
            yield call(onSubmitSaga.onSuccess, resp, values);
        }
    }

    const modalOnSubmitSaga = {
        ...(onSubmitSaga || {}),
        onSuccess: onSuccessSaga,
    };

    // TODO Fix typings to pass correct modal handler
    const formHandler = simpleFormHandler<TModel, TResponse, any>(
        prefix, key, validate, modalOnSubmitSaga, {
            handler: baseModalHandler,
        }
    );

    function* onModalCloseSaga() {
        // Clear form state
        yield put(formHandler.formReset());
    }

    return {
        ...formHandler,
        effects: [
            ...formHandler.effects,
            // Join additional effects to extend business logic of this handler
            takeEvery(baseModalHandler.CLOSE, onModalCloseSaga),
        ],
    };
}

// TODO Complete typings there
/**
 * Provides the form handler configurations.
 */
export interface FormSubmitSagaOptions<TModel, TResponse> {
    /**
     * API method to submit form data.
     */
    apiMethod?: any;
    apiMethodExt?: any;
    apiConvertValues?: (values: any) => any;
    // TODO Fix naming
    apiStatusValidor?: (resp: TResponse) => boolean;
    apiErrorSelector?: (resp: TResponse) => string|null;
    apiDataSelector?: (resp: TResponse) => TModel;
    /**
     * Callback to perform additional UX logic before actual submitting, for example confirmation by sms.
     */
    onBeforeSubmit?: any;
    /**
     * Callback triggered when form submit successfully.
     */
    onSuccess?: (resp: TResponse, values: TModel) => void;
    /**
     * List of redux actions that should be triggered when form submitted successfully.
     */
    onSuccessActions?: any[];
    /**
     * List of fetchable handlers that should be reloaded after form submitted.
     */
    onSuccessUpdate?: IFetchUpdateHandler[];
    /**
     * Callback triggered when form submit failed with error.
     */
    onFormError?: (message: string, resp: TResponse, values: TModel) => void;
    echo?: boolean;
}

interface InternalFormSubmitSagaOptions<TModel, TResponse, TBaseHandler extends IBaseHandler> extends FormSubmitSagaOptions<TModel, TResponse> {
    handler: TBaseHandler;
    id: string;
    actionName: string;
    formName: string;
    success: ({ values }) => any;
    failed: ({ values }) => any;
}

/**
 * Implement the handler to manage form state and submitting to API.
 *
 * @see {@link formHandler}
 * @see {@link modalFormHandler}
 *
 * @category Duck Handlers
 */
export type IFormHandler<TModel = any, TBaseHandler extends IBaseHandler = IBaseHandler> = IBaseHandler & TBaseHandler & {
    /**
     * @event
     */
    SUBMIT: string;
    /**
     * Submit data.
     * @param values
     * @returns
     */
    submit: (values: Partial<TModel>) => any;

    /**
     * Redux action. Triggers when form data successfully submitted via API.
     * @event
     */
    SUCCESS: string;
    /**
     * Redux action. Triggers when form data submitting failed.
     * @event
     */
    FAILED: string;

    // TODO Fix typings
    dispatchExt<TProps>(convert: (values: Partial<TModel>, props: TProps) => void): FormSubmitter<TModel>;
    /**
     * Reset the form state to initial values.
     */
    formReset: () => FormAction;
    submitExt(action: string, key?: string): FormSubmitter<TModel>;
    /**
     * Update form values.
     */
    formChange: (values: Partial<TModel>) => any;
    /**
     * Update form value for specified field.
     */
    formChangeField: <TFieldName extends NestedKeyOf<TModel>>(field: TFieldName, value: ExtractType<TModel, TFieldName>) => any;
    /**
     * Initialize form with specified initial values.
     */
    formInitialize: (data: Partial<TModel>) => any;

    form: any;
    formName: string;
    /**
     * Selector. Returns the current form values.
     * @group Selectors
     */
    formValues: SimpleSelector<TModel>;

    /**
     * Selector. Returns the current form value of specified field.
     * @param field The form field path, that need to be extracted.
     * @group Selectors
     */
    formFieldValue: <TFieldName extends NestedKeyOf<TModel>>(field: TFieldName) => SimpleSelector<ExtractType<TModel, TFieldName>>;

    /**
     * Selector. Returns the current form error.
     * @group Selectors
     */
    formError: SimpleSelector<string>;
    /**
     * Selector. Returns the flag, indicating that form currently submiting values with API request.
     * @group Selectors
     */
    formSubmitting: SimpleSelector<boolean>;
    /**
     * Selector. Returns the values, that currently submitting with API request.
     * @group Selectors
     */
    formSubmittingValues: SimpleSelector<any>;
    /**
     * Selector. Returns the data responded from server after success form submitting.
     * @group Selectors
     */
    selector: SimpleSelector<TModel>;
    /**
     * Selector. Returns the internal form state.
     * @group Selectors
     */
    internalSelector: SimpleSelector<FormState>;
}
