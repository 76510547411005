import {
    isEmptyValue,
    ValidationResult,
    ValidatorFn,
} from './common';

/**
 * TODO
 * @category Validators
 */
export function isValidEmail(v: string): boolean {
    // Убираем пробелы по краям строки
    const trimmedEmail = v.trim();
    const regExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
    return regExp.test(trimmedEmail);
}

/**
 * TODO
 * @category Validators
 */
export function requireEmail(): ValidatorFn {
    return (value: any): ValidationResult => {
        if (isEmptyValue(value)) {
            return undefined;
        }

        if (!isValidEmail(value)) {
            return 'Указанный E-mail имеет неправильный формат';
        }

        return undefined;
    };
}