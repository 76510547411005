'use client';

import React, {
    useEffect,
    useMemo,
} from 'react';
import {
    connect as reduxConnect,
    ConnectedComponent,
} from 'react-redux';
import {
    Params,
    useParams,
} from 'react-router';
import { reduxForm } from 'redux-form';
import {
    FetchingState,
    INetworkState,
    networkStateHandler,
} from './api';
import {
    CollectionItemFetchingState,
    ICollectionApiHandler,
} from './api/collection';
import { mapSelectorsToProps } from './core';
import { IFormHandler } from './form';
import { IModalHandler } from './modal';
import { IFetchHandler } from './props';
import { withWrapperDisplayName } from '../utils/buildDisplayName';

export type CollectionSourceSelector = (props: any, params: Readonly<Params<string>>) => any;
// TODO: remove any
export type CollectionConnectOptions<TSource extends string|number = any, TApiModel = any> = {
    handler: ICollectionApiHandler<TSource, TApiModel>;
    sourceSelector: CollectionSourceSelector;
}

export type FunctionComponentConnectOptions = {
    /**
     * The list of selectors that should be readed and passed to component props.
     */
    selectors?: any;
    /**
     * Specify the map of props names and redux action creators to create dispatching functions to component.
     */
    dispatch?: any;
    /**
     * Specify fetchable handlers that should be fetching by this component on render (can be changed by `fetchWhen` option).
     * When this section are used with at least one handler, then additional props are filled, that defined by `IFetchingProps` interface.
     */
    fetch?: { [key: string]: IFetchHandler };
    /**
    * Indicates when `fetch` or `collections` handlers should load data. Usefully when component mount all times, but data visible only when state changed.
    * For example, fetch data from API only when modal actually shown.
    */
    fetchWhen?: (props: any) => boolean;
    rawForm?: any;
    /** Connect component to form handler and fill related props defined by `IFormProps<TFormValues>`. */
    form?: IFormHandler;
    /** Flag, indicating that form values should be included in props. */
    includeFormValues?: boolean;
    /** Flag, indicating that form should be handled as partial. */
    partialForm?: boolean;
    /**
     * Connect modal handlers and automatically dispatch `open_<name>()` and `close_<name>()` methods to component props to manage modals shown state.
     */
    modals?: { [key: string]: IModalHandler };
    /**
     * Connect collection and extract single data based on `props` of component and `params` of router.
     * To simplify setup, use `setupCollection(...)` function to build `CollectionConnectOptions` by one line.
     *
     * This allow to read and fetch data from collection handler automatically. By default data are fetching with component render (can be changed by `fetchWhen` option).
     * When this section are used with at least one configuration, then additional props are filled, that defined by `IFetchingProps` interface (see above).
     */
    collections?: { [key: string]: CollectionConnectOptions };
}

// TODO: remove any
export function setupCollection<TSource extends string|number = any, TApiModel = any>(handler: ICollectionApiHandler<TSource, TApiModel>, sourceSelector: CollectionSourceSelector): CollectionConnectOptions {
    return {
        handler,
        sourceSelector,
    };
}

/**
 * @deprecated пожалуйста используйте новую систему хуков вместо этого метода.
 */
export function connectFunctionalComponent<P>(
    Component: React.FunctionComponent<P>,
    {
        selectors = {},
        dispatch = {},
        fetch = {},
        fetchWhen,
        rawForm = null,
        form: formHandler,
        // TODO Review all forms and switch it to false by default
        includeFormValues = true,
        partialForm = false,
        modals = {},
        collections = {},
    }: FunctionComponentConnectOptions): ConnectedComponent<React.FunctionComponent<P>, any> {
    let mapDispatchToProps = {
        ...dispatch,
    };

    for (const key in fetch) {
        mapDispatchToProps[`fetch_${key}`] = fetch[key].fetch;
        selectors[`fetch_${key}_state`] = fetch[key].state;
    }

    for (const key in modals) {
        mapDispatchToProps[`open_${key}`] = modals[key].open;
        mapDispatchToProps[`close_${key}`] = modals[key].close;
    }

    for (const key in collections) {
        mapDispatchToProps[`fetch_collection_${key}`] = collections[key].handler.fetch;
        selectors[`fetch_collection_${key}_state`] = collections[key].handler.internalSelector;
        selectors[`collection_${key}`] = collections[key].handler.selector;
    }

    if ((fetch && Object.keys(fetch)?.length > 0) || (collections && Object.keys(collections)?.length > 0)) {
        selectors['__global_network_state'] = networkStateHandler.selector;
    }

    const Wrapper: React.FunctionComponent<P> = props => {
        const globalNetworkState: INetworkState = props['__global_network_state'];
        const cacheVersion = globalNetworkState?.cacheVersion || 1;

        const fetchRequired = fetchWhen
            ? fetchWhen(props)
            : true;

        const collectionSourceKeys = {};
        const collectionKeys = Object.keys(collections);

        let params = {};
        if (collectionKeys.length > 0) {
            // В данном случае ОК, глобально метод помечен как устаревший и не должен больше использоваться
            // eslint-disable-next-line react-hooks/rules-of-hooks
            params = useParams();
        }

        for (let idx = 0; idx < collectionKeys.length; idx++) {
            const collectionKey = collectionKeys[idx];
            collectionSourceKeys[collectionKey] = collections[collectionKey].sourceSelector(props, params);
        }

        const collectionsSourceKeyDependency = Object.values(collectionSourceKeys);

        useEffect(() => {
            if (fetchRequired) {
                for (const key in fetch) {
                    const fetchAction = props[`fetch_${key}`];
                    fetchAction();
                }

                for (const collectionKey in collections) {
                    const sourceKey = collectionSourceKeys[collectionKey];
                    if (typeof sourceKey !== 'undefined' && sourceKey !== null) {
                        const fetchAction = props[`fetch_collection_${collectionKey}`];
                        fetchAction(sourceKey);
                    }
                }
            }
        }, [ fetchRequired, cacheVersion, ...collectionsSourceKeyDependency ]);

        const hasAnyFetches = Object.keys(fetch)?.length > 0;
        const fetchingReady = useMemo(
            () =>
                Object.keys(fetch)
                    .map(key => props[`fetch_${key}_state`])
                    .every((state: FetchingState) => state === 'ready'),
            Object.keys(fetch).map(key => props[`fetch_${key}_state`])
        );

        const hasAnyCollectionFetches = Object.keys(collections)?.length > 0;
        const collectionFetchingStates: CollectionItemFetchingState[] = Object.keys(collections)
            .map(collectionKey => {
                const sourceKey = collectionSourceKeys[collectionKey];
                if (typeof sourceKey !== 'undefined' && sourceKey !== null) {
                    return props[`fetch_collection_${collectionKey}_state`]?.[sourceKey];
                }

                return null;
            });

        const collectionsFetchingReady = useMemo(
            () => collectionFetchingStates.every((state?: CollectionItemFetchingState) => state?.fetching === false),
            [
                ...collectionsSourceKeyDependency,
                ...collectionFetchingStates.map((state?: CollectionItemFetchingState) => state?.fetching),
            ]
        );

        const collections_instances = {};
        for (const collectionKey in collections) {
            const sourceKey = collectionSourceKeys[collectionKey];
            if (typeof sourceKey !== 'undefined' && sourceKey !== null) {
                const data = props[`collection_${collectionKey}`]?.[sourceKey];
                collections_instances[collectionKey] = data;
            }
        }

        const mergedProps = useMemo(() => ({
            fetchingReady: (!hasAnyFetches || fetchingReady) && (!hasAnyCollectionFetches || collectionsFetchingReady),
            ...collections_instances,
            ...props,
        }), [ fetchingReady, props, ...collectionsSourceKeyDependency ]);

        return React.createElement(Component as any, mergedProps);
    };

    let WrappedComponent: React.ComponentType<P> = Wrapper;

    if (rawForm) {
        WrappedComponent = reduxForm(rawForm)(WrappedComponent as any) as any;
    }

    if (formHandler) {
        selectors = {
            ...(selectors || {}),
            // Include form values only when it really required to prevent unexpcted rerenders
            ...(includeFormValues ? { formValues: formHandler.formValues } : {}),
            formError: formHandler.formError,
            formSubmitting: formHandler.formSubmitting,
        };

        mapDispatchToProps = {
            ...mapDispatchToProps,
            formReset: formHandler.formReset,
            formChangeField: formHandler.formChangeField,
            formChange: formHandler.formChange,
            formInitialize: formHandler.formInitialize,
            onSubmit: formHandler.submit,
            customSubmit: formHandler.submit,
        };

        WrappedComponent = reduxForm({
            ...formHandler.form,
            destroyOnUnmount: !partialForm,
            forceUnregisterOnUnmount: partialForm,
        })(WrappedComponent as any) as any;
    }

    const ConnectedComponent = reduxConnect(mapSelectorsToProps(selectors || {}), mapDispatchToProps)(WrappedComponent as any) as any;

    return withWrapperDisplayName(ConnectedComponent, 'connectFC', Component);
}
