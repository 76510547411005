interface IRecaptchaService {
    ready: (cb: () => void) => void;
    execute: (siteKey: string, options?: object) => Promise<string>;
}

declare global {
    interface Window {
        SITE_KEY?: string;
        grecaptcha: IRecaptchaService & {
            enterprise: IRecaptchaService;
        };
    }
}

/**
 * TODO
 * @category Services
 */
export function withRecaptcha(action: string, enterprise = true): Promise<string> {
    if (typeof window.grecaptcha === 'undefined') {
        console.error('Recaptcha is not installed');
        return Promise.resolve('non-initialized-token');
    }

    const recaptchaService = enterprise ? window.grecaptcha.enterprise : window.grecaptcha;

    return new Promise((resolve, reject) => {
        recaptchaService
            .ready(function() {
                recaptchaService
                    .execute(window.SITE_KEY || '', {
                        action,
                    }).then(function(token) {
                        resolve(token);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            });
    });
}
