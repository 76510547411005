import React, { useCallback } from 'react';
import { buildClassNames } from '../../utils/classNameUtils';
import Tooltip from '../../ui/TooltipIcon/Tooltip';

import styles from './wizardBreadCrumbButton.module.scss';

export enum WizardStepType {
    None = '',
    Current = 'current',
    Completed = 'completed',
    Active = 'active',
}

export interface IWizardStep {
    id: number;
    title: string;
    hint?: JSX.Element|React.ReactNode;
    type?: WizardStepType;
    allowCompleted?: boolean;
    alwaysClickable?: boolean;
}

export interface IProps {
    step: IWizardStep;
    onClick?: () => void;
}

const WizardBredcrumbButton: React.FC<IProps> = props => {
    const { onClick } = props;
    const {
        title,
        hint,
        type,
        allowCompleted,
    } = props.step;

    const didClick = useCallback(e => {
        e.preventDefault();

        onClick && onClick();
    }, [ onClick ]);

    const isCompleted = type === WizardStepType.Completed;
    const isCurrent = type === WizardStepType.Current ||
        type === WizardStepType.Active;

    const className = buildClassNames(styles, [
        'wizard__bredcrumb_button',
        isCompleted && 'wizard--completed',
        isCurrent && 'wizard--current',
    ]);

    if (isCompleted && allowCompleted) {
        return (
            <Tooltip title={hint}>
                <a className={className} href="#" onClick={didClick}>
                    <span className={styles['wizard__bredcrumb_button__title']}>
                        {title}
                    </span>
                </a>
            </Tooltip>
        );
    }

    return <span className={className}>{title}</span>;
};

export default React.memo(WizardBredcrumbButton);
